<template>
  <div id="inspection-information">
    <div v-if="jcrz">
      <div class="top">
        <p><svg-icon icon-class="bz" /> {{ jcrz.testOrg }}</p>
        <!-- <a href="http://www.fclfj.com/"
        >点击查看官网<svg-icon icon-class="arrow_right"
      /></a> -->
        <a :href="jcrz.officialWebsite"
          >点击查看官网<svg-icon icon-class="arrow_right"
        /></a>
      </div>
      <div class="bottom">
        <div class="content">
          <div class="loading" v-if="loadedRatio !== 1"><van-loading size="35" /></div>
          <pdf
            v-for="item in numPages"
            :key="item"
            :src="jcrz.url"
            :page="item"
            v-show="loadedRatio === 1"
            @progress="loadedRatio = $event"
          />

          <!-- <img src="@/assets/zst/jc_1.png" alt="" />
        <img src="@/assets/zst/jc_2.png" alt="" /> -->
        </div>
      </div>
    </div>
    <none v-else />
  </div>
</template>

<script>
import pdf from "vue-pdf";
import CMapReaderFactory from "vue-pdf/src/CMapReaderFactory.js";
import none from "@/components/none.vue";

export default {
  name: "inspection-information",
  components: {
    pdf,
    none,
  },
  data() {
    return {
      jcrz: "",
      numPages: "",
      loadedRatio: 0,
    };
  },

  created() {
    this.api();

  },

  methods: {
    async api() {
      let goods = JSON.parse(sessionStorage.getItem("goods"));
      const data = await this.$API.trackBack.getdetectionPdf({
        object: {
          barcode: goods.barcode,
          enterpriseId: goods.enterpriseId,
          // barcode: "6970643730074",
          // enterpriseId: "7C05C70D5F7D49D8AC4DE9AF83F583D7",
        },
      });
      if (data.data.data !== null) {
        this.jcrz = data.data.data;
        this.jcrz.url = `http://14.29.162.130:6603/file/fileDownload/${
          data.data.data.url.split("//")[1]
        } `;
        this.getTitlePdfurl();
      }
      console.log("检测认证", this.jcrz);
    },
    getTitlePdfurl() {
      this.pdfSrc = pdf.createLoadingTask({
        url: this.jcrz.url,
        CMapReaderFactory,
      }); //解决中文乱码问题
      this.pdfSrc.promise.then((pdf) => {
        this.numPages = pdf.numPages;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#inspection-information {
  padding: 15px 12px;
  font-size: 14px;
  background-color: #f5f5f5;
  // .top {
  //   border-radius: 8px;
  //   background: #ffffff;
  //   padding: 15px 12px;
  //   display: flex;
  //   img {
  //     width: 70px;
  //     height: 70px;
  //     border-radius: 6px;
  //     margin-right: 12px;
  //   }
  //   ul {
  //     display: flex;
  //     flex-direction: column;
  //     justify-content: space-between;
  //     padding: 2px 0;
  //     flex: 1;
  //     li {
  //       font-size: 14px;
  //       color: #333333;
  //       overflow: hidden;
  //       display: flex;
  //       height: 14px;
  //       line-height: 14px;

  //       span {
  //         color: #888888;
  //         text-align: justify;
  //       }
  //       p {
  //         flex: 1;
  //         white-space: nowrap;
  //         overflow: hidden;
  //         text-overflow: ellipsis;
  //       }
  //     }
  //   }
  // }

  .top {
    height: 46px;
    background: #ffffff;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 8px 0 13px;
    p {
      font-size: 15px;
      color: #333333;
    }
    a {
      font-size: 14px;
      color: #888888;
      .svg-icon {
        margin-left: 6px;
      }
    }
  }
  .bottom {
    margin-top: 12px;
    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 15px 12px;
      background: #f8fafe;
      border-radius: 8px 8px 0px 0px;
      p {
        color: #333;
      }
      a {
        color: #0787f4;
      }
    }
    .content {
      background: #fff;
      padding: 15px 12px;
      // border-radius: 0px 0px 8px 8px;
      border-radius: 8px;
      img {
        width: 100%;
        margin-bottom: 12px;
      }
      img:last-child {
        margin: 0;
      }
      .loading {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 200px 0;
      }
    }
  }
}
</style>